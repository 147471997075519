.card {
    cursor: pointer;
    width: auto;
    height: auto;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    font-family: Arial, sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header {
    position: relative;
    background-color: #747a81;
    color: #ffffff;
    display: flex;
    align-items: center;
}

.sap-logo {
    width: 100%;
    /* max-height: 200px; */
    position: relative;
    object-fit: cover;
    /* height: 200px; */
    margin-right: 16px;
    transition: opacity 0.5s ease, filter 0.5s ease;
}

.sap-logo:hover {
    opacity: 0.5;

    filter: blur(1px);
}

.mentor-info {
    padding-left: 10px;
    position: absolute;
    flex-grow: 1;
}

.mentor-info {
    display: flex;
    padding-right: 10px;
    justify-items: center;
    align-items: center;
    background-color: rgba(16, 16, 16, 0.5);
    color: white;
    margin: 0;
    font-size: 14px;
}

.mentor-role {
    font-size: 14px;
    margin: 4px 0;
}

.mentor-icon {
    font-size: 18px;
}

.card-body {
    line-height: 1.4em;
    padding-left: 16px;
    padding-right: 10px;
    color: #333333;
    font-size: 16px;
}

.card-footer {
    padding: 8px 16px;
    background-color: #f9f9f9;
    border-top: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.date {
    font-size: 12px;
    color: #7f7c7c;
}

.comments {
    font-size: 14px;
    color: #666666;
}

.card-title {
    font-weight: bold;
    color: rgb(71, 140, 172);

}